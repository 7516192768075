//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import Footer from '~/components/footer.vue'
  export default {
    methods: {
      async getAuth() {
        if (this.$route.fullPath == '/User/Logout') {
          return false;
        }
        try {
          var url = "https://publicapi.teoark.org/getauth";
          const cache = await caches.open("auth")
          const response = await cache.match(url, {
            ignoreSearch: true,
          })
          var body = await response.json();
          this.$storage.setUniversal("Jwt", body);
          return true;
        }
        catch (err) {
          return false;
        }
      },
      async saveAuth() {
        if (this.$route.fullPath == '/User/Logout') {
          return false;
        }
        try {
          var url = "https://publicapi.teoark.org/getauth";
          const cache = await caches.open("auth");
          const response = await fetch(url, {credentials: 'include'})
          return cache.put(url, response);
        }
        catch (err) {
          return false;
        }
      }
    },
    async mounted() {
      if(process.client) {
        if (window.CSS && CSS.supports('color', 'var(--primary)')) {
          let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
          document.documentElement.style.setProperty('--vh', `${vh}px`);
        }



        var isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
        const workbox = await window.$workbox;
        if (workbox) {
          var Jwt = this.$storage.getUniversal("Jwt")
          var auth = this.$storage.getUniversal("auth")
          auth = (typeof auth != "undefined" && auth != null ? true : false);
          Jwt = (typeof Jwt != "undefined" && Jwt != null ? true : false);
          if (Jwt && auth == false) {
            if (await this.saveAuth() != false) {
              this.$storage.setUniversal("auth", "1");
            }
          }
          if (Jwt == false) {
            if (await this.getAuth()) {
              window.location.href = '/Home';
            }
          }
          workbox.addEventListener('installed', (event) => {
            if (event.isUpdate) {
              if ('caches' in window) {
                caches.keys()
                    .then(function(keyList) {
                      return Promise.all(keyList.map(function(key) {
                        return caches.delete(key);
                      }));
                    })
              }
            }
          });
        }
      }
    },
    components: {
      Footer
    }
  }
