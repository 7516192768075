export const Header = () => import('../../components/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const Icons = () => import('../../components/Icons.vue' /* webpackChunkName: "components/icons" */).then(c => wrapFunctional(c.default || c))
export const MediaGrid = () => import('../../components/MediaGrid.vue' /* webpackChunkName: "components/media-grid" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const NoAuthNavBar = () => import('../../components/NoAuthNavBar.vue' /* webpackChunkName: "components/no-auth-nav-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchMediaCard = () => import('../../components/SearchMediaCard.vue' /* webpackChunkName: "components/search-media-card" */).then(c => wrapFunctional(c.default || c))
export const SearchMediaGrid = () => import('../../components/SearchMediaGrid.vue' /* webpackChunkName: "components/search-media-grid" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const MediaCard = () => import('../../components/mediaCard.vue' /* webpackChunkName: "components/media-card" */).then(c => wrapFunctional(c.default || c))
export const Pdfviewer = () => import('../../components/pdfviewer.vue' /* webpackChunkName: "components/pdfviewer" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
