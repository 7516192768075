export default {
    methods: {
        async doDelay(ms) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve()
                }, ms)
            })
        },
        async doAlgoliaSearch(value) {
            this.loading = true
            const Jwt = this.$storage.getUniversal("Jwt")
            this.searchString = value;
            var exactmatch = (this.search.exactmatch ? '&matchlevel=full': '')

            var mediaids = '';
            if (typeof this.olddata != "undefined" && typeof this.olddata.length != "undefined" && this.olddata.length > 0) {
                for (var item of this.olddata) {
                    mediaids += item.Id + ',';
                }
                if (mediaids.substring(mediaids.length-1) == ",") {
                    mediaids = mediaids.substring(0, mediaids.length-1);
                }
            }


            var attributes = '';
            for (const [key, value] of Object.entries(this.search.attributes)) {
                if (value) {
                    attributes += key + ',';
                }
            }
            if (attributes.substring(attributes.length-1) == ",") {
                attributes = attributes.substring(0, attributes.length-1);
            }
            if (attributes == '') {
                for (const [key, value] of Object.entries(this.search.attributes)) {
                    attributes += key + ',';
                }
                if (attributes.substring(attributes.length-1) == ",") {
                    attributes = attributes.substring(0, attributes.length-1);
                }
            }
            var data = new FormData();
            data.append('type', 'media');
            data.append('search', value);
            data.append('attributes', attributes);
            data.append('mediaids', mediaids);
            if (typeof Jwt != "undefined" && Jwt != null) {
                data.append('Jwt', Jwt);
            }
            try {
                var response = await fetch('https://searchapi.teoark.org/', {
                    method: 'POST',
                    body: data,
                    credentials: 'same-origin'
                })
                if (response.ok == false) {
                    throw Error(response.status)
                }
                response = await response.json();
                this.session = response.session;
                var hits = response.hits;
                this.data.media = hits;
                this.loading = false
            }
            catch (error) {
                this.loading = false
            }
        },
        limitByChars(value, limit) {
            if(typeof value == "undefined") {
                return '';
            }
            if(value.length > limit) {
                value = value.substring(0, limit)
                value = value.replace(/\s?$/,'')
                return value + '...'
            }
            else {
                return value;
            }
        },
        dynamicSort(property) {
            var sortOrder = 1;
            if (property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a, b) {
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        },
        returnFallback(event, val) {
            if(event.target.src == event.target.dataset.fallback) {
                event.target.src = 'https://via.placeholder.com/1200.png/FFFFFF/4682b4?text=Bild%20saknas';
            }
            else {
                event.target.src = event.target.dataset.fallback;
            }
        },
        async clearSearch() {
            if (typeof this.searchString != 'undefined') {
                this.searchString = '';
            }
        },
        checkifempty(string) {
            if (string) {
                return string;
            } else {
                return 'Okänd';
            }
        },
        getDate(date) {
            if (date) {
                if (date.includes("0000-00-00")) {
                    return 'Okänd';
                }
                if (date == "") {
                    return 'Okänd';
                }
                if (/[a-zA-Z]/g.test(date)) {
                    return date
                }
                return date.slice(0, 10);
            } else {
                return 'Okänd';
            }
        }
    }
}